/* Container for the entire header */
.header-container {
    position: relative;
    background: url('./cover.png') no-repeat center center;
    background-size: cover;
    background-position: 50% 15%;
    text-align: center;
    padding: 100px 20px;
    margin-bottom: 5px;
    margin-top: 65px;
    color: white;
}

/* Content styling */
.header-content {
    z-index: 2;
    position: relative;
    font-family: 'Jost', sans-serif;
}

.header-title {
    font-size: 2.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
    direction: ltr;
}

.header-subtitle {
    font-size: 3rem;
    margin-bottom: 50px;
    direction: ltr;
}

/* Overlay styling */
.overlay-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 85px;
    background: url('./overlay-bottom.png') bottom center no-repeat;
    background-size: contain;
    z-index: 1;
}
