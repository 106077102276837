/* Importing the Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');



/* Container for the EditorJS content */
.editorjs-content {
  margin: 0 auto;
}


.editorjs-content * {
    font-family: 'Poppins', sans-serif;

}
/* Paragraph */
.editorjs-content-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #444;
}

/* Table */
.editorjs-content-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.editorjs-content-table th,
.editorjs-content-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.editorjs-content-table th {
  background-color: #f1f1f1;
  font-weight: 600;
}

.editorjs-content-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* List */
.editorjs-content-list {
  margin-bottom: 20px;
}

.editorjs-content-list ol,
.editorjs-content-list ul {
  margin-left: 20px;
}

.editorjs-content-list li {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}

/* Warning */
.editorjs-content-warning {
  background-color: #f9d5d3;
  padding: 15px;
  border-left: 5px solid #f44336;
  margin-bottom: 20px;
}

.editorjs-content-warning h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

/* Code Block */
.editorjs-content-code {
  background-color: #2d2d2d;
  color: #f1f1f1;
  padding: 15px;
  border-radius: 8px;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  margin-bottom: 20px;
}

/* Links */
.editorjs-content-link {
  color: #3498db;
  text-decoration: none;
}

.editorjs-content-link:hover {
  text-decoration: underline;
}

/* Raw HTML */
.editorjs-content-raw-html {
  margin-bottom: 20px;
}

/* Heading */
.editorjs-content-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.editorjs-content-heading h2 {
  color: #222;
}

/* Quote */
.editorjs-content-quote {
  font-style: italic;
  color: #777;
  border-left: 4px solid #3498db;
  padding-left: 15px;
  margin-bottom: 20px;
}

/* Checklist */
.editorjs-content-checklist {
  margin-bottom: 20px;
}

.editorjs-content-checklist ul {
  list-style-type: none;
  padding: 0;
}

.editorjs-content-checklist li {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.editorjs-content-checklist input[type="checkbox"] {
  margin-right: 10px;
}

/* Delimiter */
.editorjs-content-delimiter {
  border-top: 2px solid #ddd;
  margin: 20px 0;
}


  
    .editorjs-content-image img {
        width: 50%;
        height: 100%;
        object-fit: cover; /* Ensures images cover their container */
    }


    .editorjs-content-border {
        border: 2px solid #ccc; /* Adds a border around the image */
        padding: 4px;
      }
      
      .editorjs-content-background {
        background-color: #f0f0f0; /* Adds a light background */
        padding: 8px;
      }
      
      .editorjs-content-stretched {
        width: 100vw; /* Stretches the image to viewport width */
        margin-left: calc(-50vw + 50%); /* Centers stretched image */
      }