.companies-carousel {
    position: relative;
    margin: 0 auto; /* Centre horizontalement le carrousel */
    overflow: hidden; /* Masque tout ce qui dépasse */
  }
  
  .company-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Fade blanc à gauche */
  .companies-carousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
    z-index: 1;
  }
  
    /* Fade blanc à droite */
.companies-carousel::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
    z-index: 1;
  }