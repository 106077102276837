.container3 {
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    'A'
    'B'
    'C'
    'D'
    'E';
}

@media (min-width: 576px) {
  .container3 {
    grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
    grid-template-areas:
      'A B C E'
      'A D D E';
  }
}

.container3 .article:nth-child(1) {
  grid-area: A;
}

.container3 .article:nth-child(2) {
  grid-area: B;
}

.container3 .article:nth-child(3) {
  grid-area: C;
}

.container3 .article:nth-child(4) {
  grid-area: D;
}

.container3 .article:nth-child(5) {
  grid-area: E;
}

.container3 img {
  width: 100%;
  height: 100%; /* Adjust this height to make the images longer */
  object-fit: cover;
}

.container3 .article {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.article-content {
  position: absolute;
  bottom: -100%; /* Start hidden, at the bottom */
  left: 0;
  width: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.3s ease-in-out;
}

.article:hover .article-content {
  bottom: 0; /* Move up to reveal content */
}

.article-content h2 {
  color: #fff;
  font-size: 20px;
  margin: 0;
}

.article-content p {
  color: #fff;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
}
