/* style.css */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  
  .big-container {
    display: flex;
    height: 100vh;
  }
  

  

  #project_managment * {
    font-family: InterVariable !important;
  }

  @media (max-width: 768px) {
    .stats-container {
      margin: 0 0 !important;
    }
  }

  .stats-container {
    margin: 10px 200px;
  }
