@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("./fonts/InterVariable.woff2") format("woff2");
}
@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("./fonts/InterVariable-Italic.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: InterVariable, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-icon {
  color: #fff; /* Couleur de l'icône */
  animation: spin 1s linear infinite; /* Appliquer l'animation de rotation */
}


* {
  font-family: InterVariable !important;}

#signup *, #footer *, #playground *, .main-content * {
  font-family: InterVariable !important;
}

#footer {
  margin-top: 8rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#navbar {
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
}

.navbar-active {
  background-color: rgba(255, 255, 255, 0.64) !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-color: rgba(255, 255, 255, 0.06);
  border-bottom-width: 1px;
}

.text1 {
  color: #7d7f83ab;
  font-size: 1.25rem;
  font-weight: 500;

}

.text2 {
  color: #0e1012;
  font-style: normal ;
}

.hero-image {
  width: 100%;
  max-width: 400px;
  transform: scale(1.5) translateX(10%);
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  animation: floating 3s infinite;
  transition: opacity 0.5s;
  cursor: pointer;
}

.hero-image:hover {
  opacity: 1;
}

@keyframes floating {
  0% { 
    transform: scale(1.5) translate(10%, 0px); 
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  50% { 
    transform: scale(1.5) translate(10%, 2px); 
    box-shadow: 0px 20px 30px rgba(10, 1, 1, 0.247);
  }
  100% { 
    transform: scale(1.5) translate(10%, 0px); 
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }    
}


.icontainer {
  max-width: 80% !important;
}

@media (max-width: 600px) {
  .icontainer {
    max-width: 100% !important;
  }
}