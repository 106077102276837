.store-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    text-align: center;
    position: absolute;
    overflow: hidden; /* Empêche le défilement du contenu de ce conteneur */
  }

  .form * {
    font-family: "InterVariable" !important;
  }


  
@media (max-width: 768px) {
  .tag-selector {
    overflow-y: auto;
    height: 60vh;
  }
}

  .store-container .form {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.fade-enter {
  opacity: 0;
  transform: translateX(0%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(0%);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.join-container {
  min-height: 100vh;
  background-color: #f5f5f5;
}
  
  .store-container .form2 .next{
    font-size: 1.5rem;
    margin-left: 10px;
    color: #7A1928;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;
}

.store-container .form2 .previous{
    font-size: 1.5rem;
    margin-right: 10px;
    color: #7A1928;
    background-color: transparent;
    cursor: pointer;
    z-index: 5;

}
.store-container .form2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
}

.next-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #7A1928;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}